const BASE_URL = 'https://buy.api.mashihuan.com'
// const BASE_URL = 'http://localhost:3003'

const LG_SCREEN_WIDTH = 992 // antd大屏宽度
const MID_SCREEN_WIDTH = 768 // antd中屏宽度

export {
  BASE_URL,
  LG_SCREEN_WIDTH,
  MID_SCREEN_WIDTH
}