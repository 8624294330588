import { Map } from 'immutable'

import * as actionTypes from './constants'

const defaultState = Map({
  banners: []
})

function reducer(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_BANNERS:
      return state.set('banners', action.banners)
    default:
      return state
  }
}

export default reducer