import React from 'react'
import { Redirect } from 'react-router-dom'

import DefaultLayout from '@/layouts/default'

const routes = [
  {
    path: '/login',
    component: React.lazy(_ => import('@/pages/login')),
    hidden: true
  },
  {
    path: '/',
    component: DefaultLayout,
    meta: { title: 'Dashboard', icon: 'DashboardOutlined' },
    routes: [
      {
        path: '/',
        exact: true,
        hidden: true,
        render: () => (
          <Redirect to="/dashboard" />
        )
      },
      {
        path: '/dashboard',
        component: React.lazy(_ => import('@/pages/dashboard')),
        meta: { title: 'Dashboard', icon: 'DashboardOutlined' }
      },
      {
        path: '/user',
        exact: true,
        component: React.lazy(_ => import('@/pages/user/user')),
        meta: { title: '用户中心', icon: 'UserOutlined' }
      },
      {
        path: '/user/detail',
        hidden: true,
        component: React.lazy(_ => import('@/pages/user/detail')),
        meta: { activeMenu: '/user' }
      },
      {
        path: '/banner',
        component: React.lazy(_ => import('@/pages/banner')),
        meta: { title: '轮播图管理', icon: 'PictureOutlined' }
      },
      {
        path: '/category',
        component: React.lazy(_ => import('@/pages/category')),
        meta: { title: '商品分类', icon: 'GoldOutlined' }
      },
      {
        path: '/goods',
        component: React.lazy(_ => import('@/pages/goods')),
        meta: { title: '商品管理', icon: 'ShopOutlined' }
      },
      {
        path: '/menu',
        exact: true,
        component: React.lazy(_ => import('@/pages/menu')),
        meta: { title: '菜谱管理', icon: 'ReadOutlined' }
      },
      {
        path: '/menu/detail/:id',
        component: React.lazy(_ => import('@/pages/menu/detail')),
        hidden: true,
        meta: { activeMenu: '/menu' }
      },
      {
        path: '/order',
        component: React.lazy(_ => import('@/pages/order')),
        meta: { title: '订单管理', icon: 'PrinterOutlined' }
      } 
    ]
  },
  // {
  //   path: '/dashboard',
  //   component: DefaultLayout,
  //   routes: [
  //     {
  //       path: '/dashboard',
  //       component: React.lazy(_ => import('@/pages/dashboard')),
  //       meta: { title: 'Dashboard', icon: 'DashboardOutlined' }
  //     }
  //   ]
  // },
  // {  
  //   path: '/user',
  //   component: DefaultLayout,
  //   meta: {title: '用户相关', icon: 'UsergroupAddOutlined'},
  //   routes: [
  //     {
  //       path: '/user',
  //       exact: true,
  //       component: React.lazy(_ => import('@/pages/user/user')),
  //       meta: { title: '用户中心', icon: 'UserOutlined' }
  //     },
  //     {
  //       path: '/user/detail',
  //       hidden: true,
  //       component: React.lazy(_ => import('@/pages/user/detail')),
  //       meta: { activeMenu: '/user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/banner',
  //   component: DefaultLayout,
  //   routes: [
  //     {
  //       path: '/banner',
  //       component: React.lazy(_ => import('@/pages/banner')),
  //       meta: { title: '轮播图管理', icon: 'PictureOutlined' }
  //     }
  //   ]
  // },
  // {
  //   path: '/category',
  //   component: DefaultLayout,
  //   routes: [
  //     {
  //       path: '/category',
  //       component: React.lazy(_ => import('@/pages/category')),
  //       meta: { title: '商品分类', icon: 'GoldOutlined' }
  //     }
  //   ]
  // },
  // {
  //   path: '/goods',
  //   component: DefaultLayout,
  //   routes: [
  //     {
  //       path: '/goods',
  //       component: React.lazy(_ => import('@/pages/goods')),
  //       meta: { title: '商品管理', icon: 'ShopOutlined' }
  //     }
  //   ]
  // },
  // {
  //   path: '/menu',
  //   component: DefaultLayout,
  //   routes: [
  //     {
  //       path: '/menu',
  //       component: React.lazy(_ => import('@/pages/menu')),
  //       meta: { title: '菜谱管理', icon: 'ReadOutlined' }
  //     }
  //   ]
  // },
  // {
  //   path: '/order',
  //   component: DefaultLayout,
  //   routes: [
  //     {
  //       path: '/order',
  //       component: React.lazy(_ => import('@/pages/order')),
  //       meta: { title: '订单管理', icon: 'PrinterOutlined' }
  //     }
  //   ]
  // },
  {
    path: '*',
    component: React.lazy(_ => import('@/pages/404')),
    hidden: true
  }
]

export default routes