import { Map } from 'immutable'
import * as actionTypes from './constants'

const defaultState = Map({
  goods: [],
  count: 0
})

function reducer(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_GOODS:
      state = state.set('count', action.count)
      return state.set('goods', action.goods)
    default:
      return state
  }
}

export default reducer