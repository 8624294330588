import React, { memo } from 'react'
import { Redirect } from 'react-router-dom'

export default memo(function Auth(props) {
  const token = sessionStorage.getItem('token')
  if(!token && props.location.pathname !== '/login') {
    return <Redirect to="/login" />
  }

  if(token && props.location.pathname === '/login') {
    return <Redirect to="/" />
  }

  return props.children
})
