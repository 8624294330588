import { Map } from 'immutable'
import * as actionTypes from './constants'

const defaultState = Map({
  menus: [],
  count: 0,
  menu: {}
})

function reducer(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_MENUS:
      state = state.set('menus', action.menus)
      return state.set('count', action.count)
    case actionTypes.CHANGE_MENU_DETAIL:
      return state.set('menu', action.menu)
    default:
      return state
  }
}

export default reducer