import React, { memo, Suspense, useCallback, useEffect, useState } from 'react'
import { renderRoutes } from 'react-router-config'

import { LG_SCREEN_WIDTH } from '@/common/config'
import { debounce } from '@/utils'

import Auth from '../auth'
import { Layout } from 'antd'
import AppSider from '@/components/app-sider'
import AppHeader from '@/components/app-header'
import { DefaultLayoutWrapper } from './style'
const { Content } = Layout

export default memo(function DefaultLayout(props) {
  const [windowWidth, setWindowWidth] = useState(() => document.body.clientWidth)
  const [collapsed, setCollapsed] = useState(() => props.windowWidth > LG_SCREEN_WIDTH)

  useEffect(() => {
    const resizeHandler = debounce(handleWindowResize)
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const handleWindowResize = (e) =>{
    const width = document.body.clientWidth
    setWindowWidth(width)
    setCollapsed(width < LG_SCREEN_WIDTH)
  }

  const switchCollapsed = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  return (
    <Auth {...props}>
      <DefaultLayoutWrapper collapsed={collapsed}>
        <Layout className="app-layout">
          {/* logo & 侧边栏 */}
          <div className="app-sider-wrap">
            <AppSider {...props} breakpoint='lg' windowWidth={windowWidth} collapsed={collapsed} />
          </div>
          <Layout className="app-main">
            {/* 右侧header */}
            <AppHeader {...props} windowWidth={windowWidth} switchCollapsed={switchCollapsed} />
            <Content className="app-content">
              <Suspense fallback={<div>loading</div>}>
                { renderRoutes(props.route.routes) }
              </Suspense>
            </Content>
          </Layout>
        </Layout>
      </DefaultLayoutWrapper>
    </Auth>
  )
})
