import { combineReducers } from 'redux-immutable'

import userReducer from './modules/user'
import bannerReducer from './modules/banner'
import categoryReducer from './modules/category'
import goodsReducer from './modules/goods'
import menuReducer from './modules/menu'

const reducer = combineReducers({
  user: userReducer,
  banner: bannerReducer,
  category: categoryReducer,
  goods: goodsReducer,
  menu: menuReducer
})

export default reducer