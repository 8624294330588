import { Map } from 'immutable'
import * as actionTypes from './constants'

const defaultState = Map({
  categorys: []
})

function reducer(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_CATEGORYS:
      return state.set('categorys', action.categorys)
    default:
      return state
  }
}

export default reducer