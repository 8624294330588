import { Map } from 'immutable'
import * as actionTypes from './constants'

const defaultState = Map({
  users: []
})

function reducer(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_USERS:
      return state.set('users', action.users)
    default:
      return state
  }
}

export default reducer